<template>
  <div class="dialog-content" v-if="dialog.show">
    <div class="content">
      <el-scrollbar>
        <div v-for="(item, index) in infoList" :key="index" class="infoList">
          <div
            v-if="item.type === 'user'"
            class="infoItem"
            style="justify-content: flex-end"
          >
            <span
              style="padding: 10px; border-radius: 10px; background: #409eff; color: #fff"
              >{{ item.content }}</span
            >
          </div>
          <div v-else class="infoItem" style="justify-content: flex-start">
            <pre
              v-html="item.content"
              style="
                padding: 10px;
                border-radius: 10px;
                background: #fff;
                white-space: pre-wrap;
              "
            ></pre>
          </div>
        </div>
        <div class="loading" v-if="loading">
          思考中
          <span class="drop-icon" :class="{ active: loadingIcon === 1 }"></span>
          <span class="drop-icon" :class="{ active: loadingIcon === 2 }"></span>
          <span class="drop-icon" :class="{ active: loadingIcon === 3 }"></span>
        </div>
      </el-scrollbar>
      <div class="stop" v-if="loading" @click="stopReq()">
        <i class="el-icon-s-help"></i>
        停止响应
      </div>
    </div>
    <div class="dialog-footer">
      <el-input
        v-model="problem"
        placeholder="你可以问我任何问题"
        @keydown.enter.native="send()"
      ></el-input>
      <div class="icon" @click="send()">
        <i class="el-icon-position"></i>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "SunYunAI",
  model: { prop: "formData", event: "Device" },
  props: {
    formData: {
      type: Object,
    },
    dialog: {
      type: Object,
    },
  },
  data() {
    return {
      problem: "",
      infoList: [],
      loading: false,
      loadingIcon: 1,
      timer: null,
      cancelTokenSource: null,
    };
  },
  methods: {
    async send() {
      if (this.loading) return;
      if (!this.problem) return this.$message.warning("请输入你的问题");
      this.infoList.push({ type: "user", content: this.problem });
      const content = this.problem;
      this.problem = "";
      this.loading = true;
      // 设置加载中图标高亮
      this.timer = setInterval(() => {
        this.loadingIcon = this.loadingIcon === 3 ? 1 : this.loadingIcon + 1;
      }, 500);

      // 创建取消令牌源
      if (this.cancelTokenSource) {
        this.cancelTokenSource.cancel(); // 取消之前的请求
      }
      this.cancelTokenSource = axios.CancelToken.source();
      try {
        const { data } = await axios.post(
          "https://api.deepseek.com/chat/completions",
          {
            model: "deepseek-chat",
            messages: [{ role: "user", content }],
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer sk-814a49dbfe8d438e87d4ec98ceb3d87e",
            },
            cancelToken: this.cancelTokenSource.token, // 传入取消令牌
          }
        );
        this.infoList.push({
          type: "system",
          content: data?.choices[0]?.message?.content,
        });

        // 处理响应数据
        console.log("Response:", response.data);
        return response.data;
      } catch (err) {
        console.error("Error:", err);
      } finally {
        this.loading = false;
        this.timer = null;
      }
    },
    stopReq() {
      if (this.cancelTokenSource) {
        this.cancelTokenSource.cancel(); // 取消之前的请求
      }
      this.loading = false;
      this.timer = null;
    },
  },
};
</script>

<style lang="scss" scoped>
.el-scrollbar {
  height: 100%;
  overflow: hidden;

  // 侧边滚动条显示
  ::v-deep .el-scrollbar__wrap {
    overflow-x: hidden;
    margin-bottom: 0 !important;
  }

  ::v-deep .el-scrollbar__bar.is-vertical {
    opacity: 0;
  }
}
.content {
  height: 500px;
  background: url(~@/assets/fixedImages/AIbg.png) no-repeat 100% 100%;
  position: relative;
  .stop {
    position: absolute;
    bottom: 10px;
    left: 50%; // 将元素左边缘移动到容器中间
    transform: translateX(-50%); // 向左移动自身宽度的一半，实现居中
    padding: 5px 10px;
    border: 1px solid #dedede;
    border-radius: 10px;
  }

  .loading {
    .drop-icon {
      display: inline-block;
      width: 5px;
      height: 5px;
      margin: 0 2px;
      border-radius: 50%;
      background-color: #868585;
      &.active {
        background-color: #333;
      }
    }
  }
  .infoItem {
    display: flex;

    width: 100%;
  }
}
.dialog-footer {
  .icon {
    height: 35px;
    width: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    border: 1px solid #dedede;
    font-size: 18px;
    margin-left: 10px;
    cursor: pointer;
  }
}
</style>
