var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.dialog.data
    ? _c("div", { staticClass: "dialog-content" }, [
        _c(
          "div",
          { staticClass: "content" },
          [
            _c(
              "TablePage",
              {
                key: _vm.key,
                ref: "tablePage",
                on: { handleEvent: _vm.handleEvent },
                model: {
                  value: _vm.dialog.data,
                  callback: function ($$v) {
                    _vm.$set(_vm.dialog, "data", $$v)
                  },
                  expression: "dialog.data",
                },
              },
              [
                _c("el-table-column", {
                  attrs: { slot: "expand", type: "expand", "min-width": 60 },
                  slot: "expand",
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (props) {
                          return [
                            _vm.dialog && _vm.dialog.data.columns
                              ? _c(
                                  "el-table",
                                  {
                                    staticStyle: {
                                      width: "calc(100% - 51px)",
                                      "margin-right": "1px",
                                      "margin-left": "50px",
                                    },
                                    attrs: {
                                      data: props.row.children,
                                      "show-header": false,
                                      border: "",
                                    },
                                  },
                                  _vm._l(
                                    _vm.dialog.data.columns.slice(1, 100),
                                    function (column) {
                                      return _c("el-table-column", {
                                        key: column.prop,
                                        attrs: {
                                          prop: column.prop,
                                          label: column.label,
                                          "min-width": column.minWidth,
                                          align: "center",
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function (ref) {
                                                if (ref === void 0) ref = {}
                                                var row = ref.row
                                                return [
                                                  column.type === "my-checkbox"
                                                    ? _c("el-checkbox", {
                                                        attrs: {
                                                          "true-label": 1,
                                                          "false-label": 0,
                                                        },
                                                        on: {
                                                          change: function (
                                                            $event
                                                          ) {
                                                            return _vm.$emit(
                                                              "checkbox",
                                                              row
                                                            )
                                                          },
                                                        },
                                                        model: {
                                                          value:
                                                            row[column.prop],
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              row,
                                                              column.prop,
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "row[column.prop]",
                                                        },
                                                      })
                                                    : column.type === "my-input"
                                                    ? _c("el-input", {
                                                        attrs: { size: "mini" },
                                                        model: {
                                                          value:
                                                            row[column.prop],
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              row,
                                                              column.prop,
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "row[column.prop]",
                                                        },
                                                      })
                                                    : column.type ===
                                                      "my-select-local"
                                                    ? _c("SelectLocal", {
                                                        attrs: {
                                                          option:
                                                            typeof column.option ===
                                                            "function"
                                                              ? column.option(
                                                                  row
                                                                )
                                                              : column.option,
                                                          filterable:
                                                            column.option
                                                              .filterable,
                                                          multiple:
                                                            column.option
                                                              .multiple,
                                                          disabled:
                                                            column.disabled,
                                                          clearable:
                                                            column.option
                                                              .clearable,
                                                          "collapse-tags":
                                                            column.option[
                                                              "collapse-tags"
                                                            ],
                                                          placeholder:
                                                            column.placeholder ||
                                                            "选择" +
                                                              column.label,
                                                        },
                                                        on: {
                                                          selectChange:
                                                            function () {
                                                              return typeof column.rowChange ===
                                                                "function"
                                                                ? column.rowChange(
                                                                    row
                                                                  )
                                                                : undefined
                                                            },
                                                        },
                                                        model: {
                                                          value:
                                                            row[column.prop],
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              row,
                                                              column.prop,
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "row[column.prop]",
                                                        },
                                                      })
                                                    : _c(
                                                        "span",
                                                        {
                                                          style: column.style
                                                            ? column.style(
                                                                row[
                                                                  column.prop
                                                                ],
                                                                row
                                                              )
                                                            : "",
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              column.formatter
                                                                ? column.formatter(
                                                                    row[
                                                                      column
                                                                        .prop
                                                                    ],
                                                                    row
                                                                  )
                                                                : row[
                                                                    column.prop
                                                                  ]
                                                            ) + " "
                                                          ),
                                                        ]
                                                      ),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          true
                                        ),
                                      })
                                    }
                                  ),
                                  1
                                )
                              : _vm._e(),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    468562201
                  ),
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "table-setting" },
              [
                _c(
                  "el-form",
                  {
                    attrs: {
                      inline: "",
                      model: _vm.form,
                      size: "mini",
                      "label-width": "100px",
                    },
                  },
                  [
                    _c(
                      "el-form-item",
                      {
                        staticStyle: { width: "40%" },
                        attrs: { label: "字体" },
                      },
                      [
                        _c("SelectLocal", {
                          staticStyle: {
                            width: "178px",
                            "margin-right": "10px",
                          },
                          attrs: {
                            option: {
                              data: _vm.fontData,
                              value: "value",
                              label: "label",
                            },
                          },
                          model: {
                            value: _vm.form.fontFamily,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "fontFamily", $$v)
                            },
                            expression: "form.fontFamily",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      {
                        staticStyle: { width: "40%" },
                        attrs: { label: "字体大小" },
                      },
                      [
                        _c("SelectLocal", {
                          staticStyle: {
                            width: "178px",
                            "margin-right": "10px",
                          },
                          attrs: {
                            option: {
                              data: _vm.fontSizeData,
                              value: "value",
                              label: "label",
                            },
                          },
                          model: {
                            value: _vm.form.fontSize,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "fontSize", $$v)
                            },
                            expression: "form.fontSize",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      {
                        staticStyle: { width: "40%" },
                        attrs: { label: "是否加粗" },
                      },
                      [
                        _c("SelectLocal", {
                          staticStyle: {
                            width: "178px",
                            "margin-right": "10px",
                          },
                          attrs: {
                            option: {
                              data: _vm.fontWeightData,
                              value: "value",
                              label: "label",
                            },
                          },
                          model: {
                            value: _vm.form.fontWeight,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "fontWeight", $$v)
                            },
                            expression: "form.fontWeight",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      {
                        staticStyle: { width: "40%" },
                        attrs: { label: "行高" },
                      },
                      [
                        _c("SelectLocal", {
                          staticStyle: {
                            width: "178px",
                            "margin-right": "10px",
                          },
                          attrs: {
                            option: {
                              data: _vm.heightData,
                              value: "value",
                              label: "label",
                            },
                          },
                          model: {
                            value: _vm.form.height,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "height", $$v)
                            },
                            expression: "form.height",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm.dialog.data.isPrintZero
                      ? _c(
                          "el-form-item",
                          {
                            staticStyle: { width: "40%" },
                            attrs: {
                              label: "打印数量为0的商品",
                              "label-width": "140px",
                            },
                          },
                          [
                            _c("el-checkbox", {
                              model: {
                                value: _vm.form.isPrintZero,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "isPrintZero", $$v)
                                },
                                expression: "form.isPrintZero",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          {
            staticClass: "dialog-footer",
            attrs: { slot: "footer" },
            slot: "footer",
          },
          [
            _c(
              "el-button",
              {
                on: {
                  click: function ($event) {
                    return _vm.$emit("dialogEvent", "dialogClose")
                  },
                },
              },
              [_vm._v("取 消")]
            ),
            _c(
              "el-button",
              {
                attrs: { type: "primary" },
                on: {
                  click: function ($event) {
                    return _vm.$emit("dialogEvent", "dialogChange")
                  },
                },
              },
              [_vm._v(" 确 定 ")]
            ),
          ],
          1
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }