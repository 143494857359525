// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@/assets/fixedImages/AIbg.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".el-scrollbar[data-v-ae724920] {\n  height: 100%;\n  overflow: hidden;\n}\n.el-scrollbar[data-v-ae724920]  .el-scrollbar__wrap {\n  overflow-x: hidden;\n  margin-bottom: 0 !important;\n}\n.el-scrollbar[data-v-ae724920]  .el-scrollbar__bar.is-vertical {\n  opacity: 0;\n}\n.content[data-v-ae724920] {\n  height: 500px;\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat 100% 100%;\n  position: relative;\n}\n.content .stop[data-v-ae724920] {\n  position: absolute;\n  bottom: 10px;\n  left: 50%;\n  -webkit-transform: translateX(-50%);\n          transform: translateX(-50%);\n  padding: 5px 10px;\n  border: 1px solid #dedede;\n  border-radius: 10px;\n}\n.content .loading .drop-icon[data-v-ae724920] {\n  display: inline-block;\n  width: 5px;\n  height: 5px;\n  margin: 0 2px;\n  border-radius: 50%;\n  background-color: #868585;\n}\n.content .loading .drop-icon.active[data-v-ae724920] {\n  background-color: #333;\n}\n.content .infoItem[data-v-ae724920] {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  width: 100%;\n}\n.dialog-footer .icon[data-v-ae724920] {\n  height: 35px;\n  width: 35px;\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-pack: center;\n      -ms-flex-pack: center;\n          justify-content: center;\n  -webkit-box-align: center;\n      -ms-flex-align: center;\n          align-items: center;\n  border-radius: 50%;\n  border: 1px solid #dedede;\n  font-size: 18px;\n  margin-left: 10px;\n  cursor: pointer;\n}", ""]);
// Exports
module.exports = exports;
