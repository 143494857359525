<template>
  <div class="dialog-content" v-if="dialog.data">
    <div class="content">
      <TablePage ref="tablePage" v-model="dialog.data" @handleEvent="handleEvent" :key="key">
        <el-table-column type="expand" slot="expand" :min-width="60">
          <template slot-scope="props">
            <el-table v-if="dialog && dialog.data.columns" :data="props.row.children"
              style="width: calc(100% - 51px); margin-right: 1px; margin-left: 50px" :show-header="false" border>
              <el-table-column :prop="column.prop" :label="column.label" :min-width="column.minWidth" align="center"
                v-for="column in dialog.data.columns.slice(1, 100)" :key="column.prop">
                <template slot-scope="{ row } = {}">
                  <el-checkbox v-if="column.type === 'my-checkbox'" :true-label="1" :false-label="0"
                    v-model="row[column.prop]" @change='$emit("checkbox", row)' />
                  <el-input v-else-if="column.type === 'my-input'" v-model="row[column.prop]" size="mini" />
                  <SelectLocal v-else-if="column.type === 'my-select-local'" v-model="row[column.prop]" :option="
                      typeof column.option === 'function'
                        ? column.option(row)
                        : column.option
                    " :filterable="column.option.filterable" :multiple="column.option.multiple"
                    :disabled="column.disabled" :clearable="column.option.clearable"
                    :collapse-tags="column.option['collapse-tags']"
                    :placeholder="column.placeholder || `选择${column.label}`" @selectChange="
                      () =>
                        typeof column.rowChange === 'function'
                          ? column.rowChange(row)
                          : undefined
                    " />
                  <!-- 图标内容 -->
                  <!-- <div v-else-if="column.type === 'icons'">
                    <i
                      :class="
                        column.formatter
                          ? column.formatter(row[column.prop], row).icon
                          : column.elIcon
                      "
                      :style="
                        column.formatter
                          ? column.formatter(row[column.prop], row).style
                          : column.elStyle
                      "
                    />
                  </div> -->
                  <!-- 默认 -->
                  <span v-else :style="column.style ? column.style(row[column.prop], row) : ''">{{
                      column.formatter
                        ? column.formatter(row[column.prop], row)
                        : row[column.prop]
                    }}
                  </span>
                </template>
              </el-table-column>
            </el-table>
          </template>
        </el-table-column>
      </TablePage>
      <div class="table-setting">
        <el-form inline :model="form" size="mini" label-width="100px">
          <el-form-item label="字体" style="width: 40%">
            <SelectLocal v-model="form.fontFamily" style="width: 178px; margin-right: 10px"
              :option="{ data: fontData, value: 'value', label: 'label' }" />
          </el-form-item>
          <el-form-item label="字体大小" style="width: 40%">
            <SelectLocal v-model="form.fontSize" style="width: 178px; margin-right: 10px"
              :option="{ data: fontSizeData, value: 'value', label: 'label' }" />
          </el-form-item>
          <el-form-item label="是否加粗" style="width: 40%">
            <SelectLocal v-model="form.fontWeight" style="width: 178px; margin-right: 10px"
              :option="{ data: fontWeightData, value: 'value', label: 'label' }" />
          </el-form-item>
          <el-form-item label="行高" style="width: 40%">
            <SelectLocal v-model="form.height" style="width: 178px; margin-right: 10px"
              :option="{ data: heightData, value: 'value', label: 'label' }" />
          </el-form-item>
          <el-form-item label="打印数量为0的商品" label-width="140px" style="width: 40%" v-if="dialog.data.isPrintZero">
            <el-checkbox v-model="form.isPrintZero"></el-checkbox>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <div slot="footer" class="dialog-footer">
      <el-button @click="$emit('dialogEvent', 'dialogClose')">取 消</el-button>
      <el-button type="primary" @click="$emit('dialogEvent', 'dialogChange')">
        确 定
      </el-button>
    </div>
  </div>
</template>
<script>
import { v4 } from 'uuid';
import { saveTemplate, updateTemplate } from "@/api/system/system/printTemplate";
import { mixin } from "@/components/Dialog/components/mixin.js";
import { deepCopy } from '@/utils';
export default {
  name: "PrintTemplateUpdate",
  mixins: [mixin],
  components: {
    TablePage: () => import("@/components/tablePage/index.vue"),
    SelectLocal: () => import("@/components/tablePage/select/select-local/index.vue"),
  },
  data() {
    return {
      key: v4(),
      fontData: [
        { label: "宋体", value: "Simsun" },
        { label: "黑体", value: "Simhei" },
        { label: "微软雅黑", value: "Microsoft YaHei" },
        { label: "楷体", value: "KaiTi" },
        { label: "Arial", value: "Arial" },
        { label: "Verdana", value: "Verdana" },
        { label: "Times New Roman", value: "Times New Roman" },
      ],
      fontSizeData: [
        { label: "12", value: "12px" },
        { label: "14", value: "14px" },
        { label: "16", value: "16px" },
        { label: "18", value: "18px" },
        { label: "20", value: "20px" },
        { label: "24", value: "24px" },
        { label: "28", value: "28px" },
        { label: "32", value: "32px" },
      ],
      heightData: [
        { label: "12", value: "12px" },
        { label: "14", value: "14px" },
        { label: "16", value: "16px" },
        { label: "18", value: "18px" },
        { label: "20", value: "20px" },
        { label: "24", value: "24px" },
        { label: "28", value: "28px" },
        { label: "32", value: "32px" },
        { label: "36", value: "36px" },
        { label: "40", value: "40px" },
        { label: "44", value: "44px" },
      ],
      fontWeightData: [
        { label: "不加粗", value: "initial" },
        { label: "加粗", value: "bold" },
      ],
    };
  },
  methods: {
    handleEvent(type, row, s1) {
      switch (type) {
        case "checkbox":
          if (row.property == 'isSplitPage') {
            this.dialog.data.list = this.dialog.data.list.map(v => {
              if (!(row.isSplitPage == 1 && v.prop == row.prop)) {
                v.isSplitPage = 0
              }
              return v
            })
            this.key = v4();
          } else if (row.property == 'isSort') {
            this.dialog.data.list = this.dialog.data.list.map(v => {
              if (!(row.isSort == 1 && v.prop == row.prop)) {
                v.isSort = 0
              }
              return v
            })
            this.key = v4();
          }
          break;
        default:
          break;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.dialog-content {
  ::v-deep #table-page {
    height: auto;
  }
  ::v-deep .el-card {
    margin-bottom: 0px !important;
    border: none !important;
    box-shadow: none !important;
    .cardContent {
      padding: 0 !important;
    }
    tr.row-expand-cover .el-table__cell {
      .el-table__expand-icon {
        display: none !important;
      }
    }
    //
    tr.current-row > td {
      background: #ffffff !important;
    }
    tbody tr:hover > td {
      background-color: #ffffff !important;
    }

    tbody tr:hover {
      background-color: #ffffff !important;
    }
    .el-table__fixed-right tbody tr:hover > td {
      //右边固定
      background-color: #ffffff !important;
    }

    .el-table__body tr.hover-row > td {
      background-color: #ffffff !important;
    }
    .el-tablle--enable-row-hover .el-table__body tr:hover > td {
      background-color: #ffffff !important;
    }
  }
  .table-setting {
    margin-top: 8px;
  }
}
</style>
