var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.dialog.show
    ? _c("div", { staticClass: "dialog-content" }, [
        _c(
          "div",
          { staticClass: "content" },
          [
            _c(
              "el-scrollbar",
              [
                _vm._l(_vm.infoList, function (item, index) {
                  return _c("div", { key: index, staticClass: "infoList" }, [
                    item.type === "user"
                      ? _c(
                          "div",
                          {
                            staticClass: "infoItem",
                            staticStyle: { "justify-content": "flex-end" },
                          },
                          [
                            _c(
                              "span",
                              {
                                staticStyle: {
                                  padding: "10px",
                                  "border-radius": "10px",
                                  background: "#409eff",
                                  color: "#fff",
                                },
                              },
                              [_vm._v(_vm._s(item.content))]
                            ),
                          ]
                        )
                      : _c(
                          "div",
                          {
                            staticClass: "infoItem",
                            staticStyle: { "justify-content": "flex-start" },
                          },
                          [
                            _c("pre", {
                              staticStyle: {
                                padding: "10px",
                                "border-radius": "10px",
                                background: "#fff",
                                "white-space": "pre-wrap",
                              },
                              domProps: { innerHTML: _vm._s(item.content) },
                            }),
                          ]
                        ),
                  ])
                }),
                _vm.loading
                  ? _c("div", { staticClass: "loading" }, [
                      _vm._v(" 思考中 "),
                      _c("span", {
                        staticClass: "drop-icon",
                        class: { active: _vm.loadingIcon === 1 },
                      }),
                      _c("span", {
                        staticClass: "drop-icon",
                        class: { active: _vm.loadingIcon === 2 },
                      }),
                      _c("span", {
                        staticClass: "drop-icon",
                        class: { active: _vm.loadingIcon === 3 },
                      }),
                    ])
                  : _vm._e(),
              ],
              2
            ),
            _vm.loading
              ? _c(
                  "div",
                  {
                    staticClass: "stop",
                    on: {
                      click: function ($event) {
                        return _vm.stopReq()
                      },
                    },
                  },
                  [
                    _c("i", { staticClass: "el-icon-s-help" }),
                    _vm._v(" 停止响应 "),
                  ]
                )
              : _vm._e(),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "dialog-footer" },
          [
            _c("el-input", {
              attrs: { placeholder: "你可以问我任何问题" },
              nativeOn: {
                keydown: function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  ) {
                    return null
                  }
                  return _vm.send()
                },
              },
              model: {
                value: _vm.problem,
                callback: function ($$v) {
                  _vm.problem = $$v
                },
                expression: "problem",
              },
            }),
            _c(
              "div",
              {
                staticClass: "icon",
                on: {
                  click: function ($event) {
                    return _vm.send()
                  },
                },
              },
              [_c("i", { staticClass: "el-icon-position" })]
            ),
          ],
          1
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }