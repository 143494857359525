<template>
  <div class="dialog-content" v-loading="initLoading" :style="cssVar">
    <div class="content">
      <div class="view-top">
        <el-tooltip class="item" effect="dark" content="打印纸张设置" placement="bottom">
          <i class="el-icon-tickets" @click="openPageDialog()" />
        </el-tooltip>
        <el-tooltip class="item" effect="dark" content="明细列表设置" placement="bottom">
          <i class="el-icon-s-grid" @click="openListDialog()" />
        </el-tooltip>
      </div>
      <div class="view-bottom">
        <div>
          <div v-for="(item,index) in Array.from({ length: maxPage }, (_, i) => i + 1) || []" :key="item">
            <div class="print-container" :id="`print-container${index}`" :style="{
            paddingLeft: `${getDpi(pageConfig.paddingLeft)}px`,
            paddingRight: `${getDpi(pageConfig.paddingRight)}px`,
            paddingTop: `${getDpi(pageConfig.paddingTop)}px`,
            paddingBottom: `${getDpi(pageConfig.paddingBottom)}px`,
            width: `${
             getDpi(pageConfig.width)
            }px`,
            height: `${
             getDpi(pageConfig.height)
            }px`,
          }">
              <div class="top-view top-view-title">
                <div style="width:100%;height:100%">
                  <div style="
                font-size: 20px;
                font-weight: bold;
                color: #000000;
                text-align: center;
                margin-bottom: 5px
              ">
                    {{ data.pageTitle||'' }}
                  </div>
                  <div class="x-b" style="margin-bottom: 5px">
                    <div>
                      <div style="margin-bottom:5px">
                        {{`打印时间：${moment().format("yyyy-MM-DD HH:mm:ss")}`}}
                      </div>
                      <div>
                        {{ `${data.filters.topRightBillNo||''}` }}
                      </div>
                    </div>
                    <div>
                      <div style="margin-bottom:5px">
                        {{`打印人：${$store.state.user.userinfo.nickName}`}}
                      </div>
                      <div v-if="data && data.filters">
                        {{ `${data.filters.topRightText || ""}` }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- 表格 -->
              <div :style="{
              marginRight: '2px',
              width: 'calc(100% - 1px)',
              height: `${tableHeihgt}px`,
            }">
                <TablePageWrapper ref="tablePage" :options="getOptionsForItem(item)" />
              </div>
              <div class="top-view" :style="{
              height: `${pageConfig.bottomStyle.height}px`,
              borderTop: '1px solid #eeeeee',
            }">
                {{ `${item} - ${maxPage}` }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div slot="footer" class="dialog-footer">
      <el-button @click="$emit('dialogEvent', 'dialogClose')">取 消</el-button>
      <el-button type="primary" @click="saveSetting()">保存设置</el-button>
      <el-button type="primary" @click="printAll()">打 印</el-button>
    </div>
    <Dialog ref="Dialog" :options.sync="dialogOptions" @handleEvent="handleEvent" />
  </div>
</template>
<script>
import { mixin } from "@/components/Dialog/components/mixin.js";
import { deepCopy } from "@/utils/index.js";
import moment from "moment";
import Sortable from "sortablejs";
import jsPDF from "jspdf";
import printJS from "print-js";
import domtoimage from "@/views/system/custom/printTemplate/detail/dom-to-image.js";
import {
  getDirectList,
  saveDirect,
  updateDirect,
} from "@/api/system/system/printTemplate";

const getDpi = (num) => {
  const inch = document.createElement("div");
  inch.style.width = "1in";
  // 将div的尺寸设置为1英寸后，它会自动根据设备的分辨率进行缩放
  document.body.appendChild(inch);
  const dpi = inch.offsetWidth;
  document.body.removeChild(inch);
  const unit = Number(num);
  const inchs = unit / 25.4; // 将毫米转换为英寸,cm就除与2.54以此类推
  const px = inchs * dpi; // 将英寸转换为像素
  return Math.round(px); // 四舍五入取整数像素值
};

export default {
  name: "PrintTemplateMatchMaterialDirectPrint",
  mixins: [mixin],
  components: {
    TablePageWrapper: () => import("./TablePageWrapper"),
    Dialog: () => import("@/components/Dialog"),
  },
  data() {
    return {
      openProps: [],
      dialogOptions: {},
      initLoading: true,
      getDpi,
      options: {
        noShowColumnSet: true,//不获取列配置信息
      },
      moment,
      templateDirectId: "",
      pageConfig: {
        headPrintType: 1,
        endPrintType: 1,
        paperType: "A4", // 纸类型
        direction: 1, //打印方向
        width: 210, // 宽度 mm
        height: 297, // 高度 mm
        zIndex: 0,
        paddingLeft: 10,
        paddingRight: 10,
        paddingTop: 10,
        paddingBottom: 10,
        topStyle: {
          height: 55,
        },
        bottomStyle: {
          height: 40,
        },
        tableStyle: {
          fontFamily: "Microsoft YaHei",
          fontSize: "12px",
          fontWeight: "initial",
          height: "28px",
          lineHeight: "14px",
          maxHeight: "28px",
          minHeight: "28px",
        },
        tableColumns: [],
      },
      maxPageSize: 0,
      maxPage: 1,
      inPageNum: 1,
    };
  },
  computed: {
    tableHeihgt() {
      const { paddingTop, paddingBottom } = this.pageConfig;
      return (
        getDpi(this.pageConfig.height) -
        getDpi(paddingTop) -
        getDpi(paddingBottom) -
        80
      );
    },
    cssVar() {
      return {
        "--sumsHeight": this.options?.summary?.length
          ? this.pageConfig.tableStyle.height
          : "",
      };
    },
  },
  async mounted() {
    // 获取列表数据
    try {
      console.log('data', this.data);
      const { getOther, nowPrint, filters = {} } = deepCopy(this.data);
      this.list = filters.detail || filters.detail || [];
      let arr = [];
      try {
        arr = Object.keys(filters.filter).filter((x) => filters.filter[x] !== null);
      } catch (error) { }
      if (filters?.filter && arr?.length) {
        this.list = this.list.map((x) => ({
          ...x,
          [`${filters.key}`]: x[`${filters.key}`].filter((x) =>
            arr.every((y) => x[y] === filters.filter[y])
          ),
        }));
      }
      this.getAssembling(filters.goodsType)
      let columns = [];
      columns = nowPrint.columns;
      console.log('nowPrint1', deepCopy(columns));
      columns.unshift({
        sort: 0,
        align: "center",
        label: "序号",
        name: "序号",
        width: 20,
        prop: "lineIndex",
        isDefaultCheck: 1,
        hideTooltip: true,
      });
      columns = columns.map((x, i) => ({
        ...x,
        isDefaultCheck: 1,
        name: x.label,
        sort: i,
        hideTooltip: true,
      }));
      console.log('nowPrint2', deepCopy(columns));
      this.pageConfig.tableColumns = columns;
      this.routerPath = "/produce/bill/matchMaterial";
      const templateRes = await getDirectList({ routerPath: this.routerPath, tabIndex: filters.goodsType });
      if (templateRes?.rows?.length) {
        let nowTemplate = {};
        const data = templateRes?.rows[0];
        this.templateDirectId = data.templateDirectId;
        try {
          const nowConfig = JSON.parse(data.templateDirectContent);
          if (nowConfig.tableColumns?.length) {
            nowTemplate = nowConfig;
          }
        } catch (error) { }
        const getValue = (obj = {}) => {
          const item = nowTemplate?.tableColumns?.find?.((y) => y.prop === obj.prop);
          if (item && item.children) {
            item.minWidth = undefined;
            item.children = obj.children.map((x) => {
              const cItem = item.children.find((y) => x.prop === y.prop);
              return {
                ...cItem,
                getValue: x.getValue,
                name: x.name,
                prop: x.prop,
                label: cItem?.label,
                align: cItem?.align,
                minWidth: cItem?.minWidth,
                isDefaultCheck: cItem?.isDefaultCheck,
              };
            });
          }
          return {
            ...item,
            name: obj.name,
            prop: obj.prop,
          };
        };
        this.pageConfig = {
          ...this.pageConfig,
          ...nowTemplate,
          tableColumns: this.pageConfig.tableColumns.map((x) => ({
            ...x,
            ...getValue(x),
          })).sort((a, b) => { return a.sort - b.sort; }),
        };
      }
      await this.$nextTick();
      await this.toPages();
      await this.doLayout();
      console.log('this.pageConfig', this.pageConfig);
    } catch (error) {
      console.log("error", error);
    }
    // 先分页
    this.initLoading = false;
  },
  methods: {
    getAssembling(goodsType = 1) {
      let list = [];
      let map = new Map();
      const addItem = (item, propertyName) => {
        list.push({
          [propertyName]: item.goodsName,
          factLeadUnitQty: item.factLeadUnitQty,
          factLeadUnitName: item.factLeadUnitName,
        });
      };

      const traverse = (items, depth) => {
        items.forEach(item => {
          switch (depth) {
            case 0:
              list.push({ goodsNo: item.planGoodsNo, goodsName: item.planGoodsName, primaryRawMaterialName: item.goodsName, factLeadUnitQty: item.factLeadUnitQty, factLeadUnitName: item.factLeadUnitName });
              break;
            case 1:
              addItem(item, 'primaryRawMaterialName');
              break;
            case 2:
              addItem(item, 'secondaryRawMaterialName');
              break;
            case 3:
              addItem(item, 'tertiaryRawMaterialName');
              break;
            case 4:
              addItem(item, 'fourRawMaterialName');
              break;
            default:
              break;
          }
          if (item.children?.length > 0) {
            traverse(item.children, depth + 1);
          }
        });
      };
      switch (goodsType) {
        case 1:
          const arr = this.list.filter(x => x.parentBillDetailId == 0);
          traverse(arr, 0);
          break;
        case 2:
          list = this.list.filter(x => x.goodsInfo.goodsType == 3);
          list.forEach(item => {
            const key = `${item.goodsId}-${item.unitId}`;
            if (map.has(key)) {
              let existingItem = map.get(key);
              existingItem.factLeadBasUnitQty += item.factLeadBasUnitQty;
              existingItem.factLeadUnitQty += item.factLeadUnitQty;
            } else {
              map.set(key, { ...item });
            }
          });
          list = [...map.values()];
          break;
        case 3:
          list = this.list.filter(x => x.goodsInfo.goodsType == 2);
          list.forEach(item => {
            const key = `${item.goodsId}-${item.unitId}`;
            if (map.has(key)) {
              let existingItem = map.get(key);
              existingItem.factLeadBasUnitQty += item.factLeadBasUnitQty;
              existingItem.factLeadUnitQty += item.factLeadUnitQty;
            } else {
              map.set(key, { ...item });
            }
          });
          list = [...map.values()];
          break;
        default:
          break;
      }
      this.list = list;
    },
    getOptionsForItem(index) {
      return {
        ...this.options,
        list: this.list
          ?.slice(
            (index - 1) * this.maxPageSize,
            index * this.maxPageSize
          )
          ?.map((x, i) => ({
            ...x,
            lineIndex: (index - 1) * this.maxPageSize + i + 1,
          }))
      }
    },
    async doLayout() {
      try {
        await this.$nextTick();
        this.$refs.tablePage.$refs.table.$refs.mutipleTable.doLayout();
      } catch (error) { }
    },
    // 分页
    async toPages() {
      await this.setTabelColumn();
      const awaitTime = (t = 100) => new Promise((resolve) => setTimeout(resolve, t));
      await awaitTime(500);
      const { tableStyle } = this.pageConfig; // 一些配置项目
      const thHeight = document.querySelector(
        "#print-container0 .el-table__header-wrapper"
      ).offsetHeight;
      const lineHeight = Number(tableStyle.height.split("px")[0]) + 2; // 行高
      console.log("thHeight", thHeight, this.tableHeihgt, lineHeight);
      this.maxPageSize = Math.floor(
        (this.tableHeihgt - thHeight - (this.options.summary?.length ? 42 : 0)) /
        lineHeight
      );
      this.maxPage = Math.ceil(this.list.length / this.maxPageSize);
      await this.setTabelColumn();
    },
    showLoding() {
      this.loading = this.$loading({
        lock: true,
        text: "提交中...",
        spinner: "el-icon-loading",
        background: "rgba(255, 255, 255, 0.7)",
        customClass: "topLoading",
      });
    },
    hideLoading() {
      try {
        this.loading.close();
        this.loading = null;
      } catch (error) { }
    },
    // 打印全部
    async printAll() {
      this.initLoading = true;
      this.showLoding();
      try {
        const { paperType, direction, width, height } = this.pageConfig; // 一些配置项目
        const pdf = new jsPDF({
          unit: "mm",
          format: 'a4', // 初始格式（会被覆盖）
          orientation: 'portrait', // 初始方向（会被覆盖）
          putOnlyUsedFonts: true,
          compress: true,
          precision: 16,
        });
        pdf.internal.pageSize.setWidth(width);
        pdf.internal.pageSize.setHeight(height);
        for (let index = 0; index < this.maxPage; index++) {
          this.inPageNum = index + 1;
          const element = document.getElementById(`print-container${index}`);
          const height2 = element.offsetHeight;
          const width2 = element.offsetWidth;
          let imgData = await domtoimage.toJpeg(element, {
            height: height2,
            width: width2,
            scale: 4,
            style: { margin: 0, color: "black", background: "#ffffff" },
          });
          if (!imgData || !imgData.length) {
            this.hideLoading();
            this.$emit("dialogEvent", "dialogClose");
            return this.$message.error("打印失败：生产打印内容失败");
          }
          if (index > 0) {
            pdf.addPage();
            // 显式设置新页面的尺寸
            pdf.internal.pageSize.setWidth(width);
            pdf.internal.pageSize.setHeight(height);
          }
          pdf.addImage(imgData, "JPEG", 0, 0, width, height);
        }
        const printable = pdf.output("bloburl");
        printJS({
          printable, // 获取 Blob 对象的临时路径
          type: "pdf",
        });
      } catch (error) {
        console.log("error", error);
      }
      this.$emit("dialogEvent", "dialogClose");
      this.hideLoading();
    },
    async setTabelColumn() {
      await this.$nextTick();
      let columns = this.pageConfig.tableColumns.map((x) => ({
        ...x,
        ...(x.children?.length && x.children.find((y) => y.isDefaultCheck === 1)
          ? { children: x.children.filter((y) => y.isDefaultCheck === 1) }
          : { children: undefined }),
      }));
      columns = columns?.filter((x) => x.isDefaultCheck === 1);
      let summary = [];
      columns.forEach((x) => {
        if (x.summary === 1) {
          summary.push(x.prop);
        }
        if (x.children?.length) {
          summary = [
            ...summary,
            ...x.children.filter((x) => x.summary === 1).map((x) => x.prop),
          ];
        }
      });
      this.options = {
        list: this.list
          .slice(
            (this.inPageNum - 1) * this.maxPageSize,
            this.inPageNum * this.maxPageSize
          )
          .map((x, i) => ({
            ...x,
            lineIndex: (this.inPageNum - 1) * this.maxPageSize + i + 1,
          })),
        hideCard: true,
        cellStyle: this.pageConfig.tableStyle,
        height: "auto",
        noShowColumnSet: true,//不获取列配置信息
        maxHeight: this.tableButtonH,
        summary,
        headerDragend: (w, oldw, { property } = {}) => {
          const table = this.$refs.tablePage.$refs.table.$refs.mutipleTable;
          table.columns.forEach((x) => {
            if (x.level === 1) {
              const i = this.pageConfig.tableColumns.findIndex(
                (y) => x.property === y.prop
              );
              if (i >= 0) {
                this.pageConfig.tableColumns[i].width = x.width;
              }
            } else if (x.level === 2) {
              const i = this.pageConfig.tableColumns.findIndex(
                (y) => y.children?.length && y.children.find((z) => z.prop === x.property)
              );
              if (i >= 0) {
                const ii = this.pageConfig.tableColumns[i].children.findIndex(
                  (y) => y.prop === x.property
                );
                if (ii >= 0) {
                  this.pageConfig.tableColumns[i].children[ii].width = x.width;
                }
              }
            }
          });
        },
        hidePagination: true,
        columns,
      };
    },
    async handleEvent(type, row) {
      switch (type) {
        case "dialogChange":
          if (row.type === "PrintTemplateSetting") {
            this.pageConfig = deepCopy({ ...this.pageConfig, ...row.formData });
            await this.toPages();
          } else if (row.type === "PrintTemplateUpdate") {
            this.pageConfig.tableColumns = deepCopy(row.data.list).sort(
              (a, b) => a.sort - b.sort
            );
            const { height, fontSize } = row.formData;
            const h = Number(height.split("px")[0]);
            const s = Number(fontSize.split("px")[0]);
            this.pageConfig.tableStyle = deepCopy({
              ...row.formData,
              lineHeight: `${Math.floor(h / Math.floor(h / s))}px`,
              maxHeight: height,
              minHeight: height,
            });
            await this.toPages();
          } else if (row.type === "PrintTemplate") {
            this.printTemplateId = row?.formData?.formApiRes?.data || "";
            await this.toPages();
          }
          break;
        default:
          break;
      }
    },
    async saveSetting() {
      try {
        if (!this.templateDirectId) {
          const res = await saveDirect({
            routerPath: this.routerPath,
            tabIndex: this.data?.filters?.goodsType,
            templateDirectContent: JSON.stringify(this.pageConfig),
          });
          this.templateDirectId = res.data;
        } else {
          const res = await updateDirect({
            templateDirectId: this.templateDirectId,
            tabIndex: this.data?.filters?.goodsType,
            templateDirectContent: JSON.stringify(this.pageConfig),
          });
        }
        this.$message.success("保存设置成功");
      } catch (error) { }
    },
    openPageDialog() {
      this.dialogOptions = {
        show: true,
        title: "打印纸张设置",
        width: 760,
        type: "PrintTemplateSetting",
        formData: deepCopy(this.pageConfig),
      };
    },
    openListDialog() {
      this.dialogOptions = {
        show: true,
        title: "列表明细设置",
        width: 900,
        type: "PrintTemplateUpdate",
        formData: deepCopy(this.pageConfig.tableStyle),
        data: {
          list: deepCopy(this.pageConfig.tableColumns),
          hideCard: true,
          height: 460,
          hidePagination: true,
          noShowColumnSet: true,//不获取列配置信息
          rowKey: "prop",
          treeProps: { hasChildren: "hasChildrenss", children: "childrenss" },
          tableId: "printDialogTable",
          rowClassName: (e) => {
            if (!e.row.children?.length) return "row-expand-cover";
          },
          columns: [
            {
              prop: "expand",
              type: "slot",
            },
            {
              type: "my-checkbox",
              prop: "isDefaultCheck",
              label: "打印",
              minWidth: 60,
            },
            {
              prop: "name",
              label: "默认文本",
              minWidth: 160,
            },
            {
              type: "my-input",
              prop: "label",
              label: "自定义文本",
              minWidth: 160,
            },
            {
              type: "my-checkbox",
              prop: "summary",
              label: "是否合计",
              minWidth: 100,
            },
            {
              type: "my-input",
              print: true,
              prop: "minWidth",
              label: "列宽",
              minWidth: 100,
              formatter: (v, row) => row.width || v,
            },
            {
              type: "my-select-local",
              print: true,
              prop: "align",
              label: "对齐方式",
              minWidth: 120,
              option: {
                value: "value",
                label: "label",
                data: [
                  { value: "left", label: "左对齐" },
                  { value: "center", label: "居中对齐" },
                  { value: "right", label: "右对齐" },
                ],
              },
            },
            {
              type: "icons",
              elIcon: "el-icon-rank",
              elStyle: {
                fontSize: "22px",
              },
              prop: "sort",
              label: "排序",
              hideTooltip: true,
              minWidth: 70,
            },
          ],
        },
      };
      setTimeout(() => {
        this.$nextTick(() => {
          const el = document.querySelector(
            "#printDialogTable .el-table__body-wrapper tbody"
          );
          new Sortable(el, {
            animation: 500,
            handle: ".el-icon-rank",
            ghostClass: "blue-background-class",
            onChoose: () => {
              const ids = this.dialogOptions.data.list

                .map((x, i) => ({ ...x, cIndex: i }))
                .filter((x) => x.children?.length);

              console.log("idsidsids", ids);
              ids.forEach((x) => {
                this.$refs.Dialog.$refs.myDialog.$refs.tablePage.$refs.table.$refs.mutipleTable.toggleRowExpansion(
                  this.dialogOptions.data.list[x.cIndex],
                  false
                );
              });
            },
            onEnd: async (evt) => {
              // 移除当前项
              const currRow = this.dialogOptions.data.list.splice(evt.oldIndex, 1)[0];
              // 根据移动方向更新sort值
              if (evt.newIndex > evt.oldIndex) {
                // 下移操作，更新被挤压的元素的sort值
                for (let i = evt.oldIndex; i < evt.newIndex; i++) {
                  this.dialogOptions.data.list[i].sort--;
                }
              } else {
                // 上移操作，更新被挤压的元素的sort值
                for (let i = evt.oldIndex - 1; i >= evt.newIndex; i--) {
                  this.dialogOptions.data.list[i].sort++;
                }
              }
              // 在新位置插入当前项，并设置其sort值
              currRow.sort = evt.newIndex;
              this.dialogOptions.data.list.splice(evt.newIndex, 0, currRow);
              // 重新布局表格（如果需要）
              this.$refs.Dialog.$refs.myDialog.$refs.tablePage.$refs.table.$refs.mutipleTable.doLayout();
            },
          });
        });
      }, 500);
    },
  },
};
</script>
<style lang="scss" scoped>
.dialog-content {
  ::v-deep #table-page {
    height: auto !important;
  }
  ::v-deep .el-card {
    box-shadow: none !important;
    border: none !important;
    border-radius: 0 !important;
    .cardContent {
      padding: 0 !important;
    }
    .el-table {
      .el-table__header-wrapper th,
      .el-table__fixed-header-wrapper th {
        background: #ffffff;
      }
      th.el-table__cell,
      td.el-table__cell {
        border-bottom: 1px solid #000000;
        border-right: 1px solid #000000;
        background-color: #ffffff;
      }
      // 合计
      .el-table__footer-wrapper {
        td.is-leaf {
          border-top: 1px solid #000000;
          .cell {
            height: var(--sumsHeight) !important;
          }
        }
      }
    }
    .el-table--group,
    .el-table--border {
      border: 1px solid #000000;
    }

    .el-table__cell {
      padding: 0 !important;
      .cell {
        font-family: inherit;
        font-size: inherit;
        font-weight: inherit;
        line-height: inherit;
        max-height: inherit;
        min-height: inherit;
        padding: 0;
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: center;
        height: inherit;
        color: black;
        span {
          max-height: inherit;
          width: 100%;
        }
      }
    }
    .table-header .cell {
      height: 100%;
    }
  }

  .bold-view {
    width: 28px;
    height: 28px;
    line-height: 28px;
    text-align: center;
    border-radius: 4px;
    cursor: pointer;
    &:hover {
      background-color: #f4f5f6;
    }
  }
  i {
    margin-right: 8px;
    cursor: pointer;
    width: 28px;
    height: 28px;
    width: 28px;
    height: 28px;
    line-height: 28px;
    text-align: center;
    &:hover {
      background-color: #f4f5f6;
    }
  }

  .content {
    height: 80vh;
    min-height: 600px;
    padding: 0 !important;
    .view-top {
      width: calc(100% - 20px);
      padding: 0 10px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }
    .view-bottom {
      height: calc(100% - 40px);
      overflow-y: auto;
      padding: 20px;
      background-color: #f4f5f6;
      .print-container {
        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
        background-color: #ffffff;
        background: #ffffff;
        margin: 10px auto;
        position: relative;
        overflow: hidden;
        color: black;
        .top-view {
        }
        .top-view-title {
          display: flex;
          position: relative;
          align-items: flex-end;
          width: 100%;
          justify-content: space-between;
          font-size: 13px;
          line-height: 14px;
        }
      }
    }
  }
}
</style>
