var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.form && _vm.show
    ? _c("div", { staticClass: "dialog-content" }, [
        _c(
          "div",
          { staticClass: "content" },
          [
            _c("myForm", {
              ref: "myForm",
              attrs: { options: _vm.formOpt, "label-width": "110px" },
              on: { handleEvent: _vm.handleEvent },
              model: {
                value: _vm.form,
                callback: function ($$v) {
                  _vm.form = $$v
                },
                expression: "form",
              },
            }),
            _c(
              "div",
              { staticClass: "table-setting" },
              [
                _c(
                  "el-form",
                  {
                    attrs: {
                      inline: "",
                      model: _vm.form,
                      size: "mini",
                      "label-width": "100px",
                    },
                  },
                  [
                    _c(
                      "el-form-item",
                      {
                        staticStyle: { width: "40%" },
                        attrs: { label: "左边距" },
                      },
                      [
                        _c("el-input-number", {
                          staticStyle: { "text-align": "left" },
                          attrs: {
                            controls: false,
                            precision: 0,
                            step: 1,
                            min: 0,
                            label: "请输入左边距",
                            size: "mini",
                          },
                          model: {
                            value: _vm.form.paddingLeft,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "paddingLeft", $$v)
                            },
                            expression: "form.paddingLeft",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      {
                        staticStyle: { width: "40%" },
                        attrs: { label: "右边距" },
                      },
                      [
                        _c("el-input-number", {
                          staticStyle: { "text-align": "left" },
                          attrs: {
                            controls: false,
                            precision: 0,
                            step: 1,
                            min: 0,
                            label: "请输入右边距",
                            size: "mini",
                          },
                          model: {
                            value: _vm.form.paddingRight,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "paddingRight", $$v)
                            },
                            expression: "form.paddingRight",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      {
                        staticStyle: { width: "40%" },
                        attrs: { label: "上边距" },
                      },
                      [
                        _c("el-input-number", {
                          staticStyle: { "text-align": "left" },
                          attrs: {
                            controls: false,
                            precision: 0,
                            step: 1,
                            min: 0,
                            label: "请输入上边距",
                            size: "mini",
                          },
                          model: {
                            value: _vm.form.paddingTop,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "paddingTop", $$v)
                            },
                            expression: "form.paddingTop",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      {
                        staticStyle: { width: "40%" },
                        attrs: { label: "下边距" },
                      },
                      [
                        _c("el-input-number", {
                          staticStyle: { "text-align": "left" },
                          attrs: {
                            controls: false,
                            precision: 0,
                            step: 1,
                            min: 0,
                            label: "请输入下边距",
                            size: "mini",
                          },
                          model: {
                            value: _vm.form.paddingBottom,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "paddingBottom", $$v)
                            },
                            expression: "form.paddingBottom",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          {
            staticClass: "dialog-footer",
            attrs: { slot: "footer" },
            slot: "footer",
          },
          [
            _c(
              "el-button",
              {
                on: {
                  click: function ($event) {
                    return _vm.$emit("dialogEvent", "dialogClose")
                  },
                },
              },
              [_vm._v("取 消")]
            ),
            _c(
              "el-button",
              {
                attrs: { type: "primary" },
                on: {
                  click: function ($event) {
                    return _vm.dialogEvent("dialogChange")
                  },
                },
              },
              [_vm._v("确 定")]
            ),
          ],
          1
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }