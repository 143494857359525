<template>
  <div class="dialog-content" v-if="formOpt.length && show">
    <div class="content">
      <myForm v-model="form" ref="myForm" :options="formOpt" label-width="105px" />
    </div>
    <div slot="footer" class="dialog-footer">
      <el-button @click="$emit('dialogEvent', 'dialogClose')">取 消</el-button>
      <el-button type="primary" @click="dialogEvent(dialog.click)">确 定</el-button>
    </div>
  </div>
</template>
<script>
import { UPGRADELOG_TYPE_DATA, UPGRADELOG_TYPE_STATUS } from "@/utils/constant.js";
import {
  addLog,
  updateLog,
  getUpgradeProductVerNo,
} from "@/api/tenant/base/upgradeLogManage";
import { mixin } from "@/components/Dialog/components/mixin.js";
import moment from "moment";

const getFileVersion = (fileName, productVerIndex) => {
  let v = "";
  try {
    const verArr = fileName.split(" ").pop().split(".");
    const a = verArr.slice(0, verArr.length - 1).join(".");
    if (checkVersion({ version: a, productVerIndex })) {
      v = verArr.slice(0, verArr.length - 1).join(".");
    }
  } catch (error) {}
  return v;
};
const checkVersion = ({ version = "", productVerIndex = "" }) => {
  const arr = version?.split?.(".") || [];
  const [no, yyyy, mmdd, num] = arr;
  if (arr.length !== 4) {
    return false;
  }
  if ((productVerIndex ? no !== productVerIndex : false) || !no || no.length > 3) {
    return false;
  }
  if (
    yyyy.length !== 2 ||
    mmdd.length !== 4 ||
    !moment(`20${yyyy}${mmdd} 00:00:00`, "YYYY-MM-DD").isValid()
  ) {
    return false;
  }
  const Num = Number(num);
  if (isNaN(Num) || num.length != 2 || Num < 1 || Num > 99) {
    return false;
  }
  return true;
};

// version 版本号 productVerIndex 版本序号 check是否是校验 取数据字典 sys_product_ver_id
const getVersion = ({ version = "", productVerIndex = "" }) => {
  const arr = version?.split?.(".") || [];
  const [, yyyy, mmdd, num = 1] = arr;
  if (
    checkVersion({ version, productVerIndex }) &&
    moment(`20${yyyy}${mmdd}`, "YYYY-MM-DD").isSame(moment().format("YYYY-MM-DD"))
  ) {
    const vNum = Number(num) + 1 > 9 ? Number(num) + 1 : `0${Number(num) + 1}`;
    return `${productVerIndex}.${yyyy}.${mmdd}.${vNum}`;
  }
  const YYYYMMDD = moment().format("YY.MMDD");
  return `${productVerIndex}.${YYYYMMDD}.01`;
};

export default {
  name: "UpgradeLog",
  mixins: [mixin],
  data() {
    return {
      requests: { add: addLog, update: updateLog },
      show: false,
      formOpt: [],
    };
  },
  async created() {
    const formOpt = [
      // {
      //   model: "testUploadText",
      //   type: "input",
      //   label: "上传文件路径",
      //   loadFormat: () => "/file/customFiles/",
      //   tip: "上传文件路，不输入则自动生成文件路径",
      //   change: (v) => (this.formOpt[1].option.customName = v),
      // },
      // {
      //   model: "testUpload",
      //   type: "upload-one",
      //   label: "上传文件",
      //   option: {
      //     type: ["exe", "zip", "apk", "wgt", "ipa", "xlsx", "png", 'jpg', "jpge", "mp4"],
      //     autoUpload: false,
      //     customName: "",
      //   },
      // },
      // 上面是自定义上传功能 不用请不用放开
      {
        model: "upgradeProductId", //
        type: "local",
        label: "系统产品",
        rules: [
          {
            required: true,
            message: "请选择系统产品",
            trigger: ["blur", "change"],
          },
        ],
        option: {
          disabled: this.dialog.click !== "add",
          label: "productName",
          value: "productId",
          remoteBody: {
            productTypes: "1,2",
            status: 0,
            pageNum: 1,
            pageSize: 200,
          },
          filterable: true,
          data: this.dataObj.productListTree,
          change: async (x, obj) => {
            await this.changeOption(obj?.isSunyunApp);
            try {
              const { data } = await getUpgradeProductVerNo({
                upgradeProductId: x,
              });
              this.form.upgradeProductVer = getVersion({
                version: data,
                productVerIndex: obj.productVerIndex,
              });
              this.form.productVerIndex = obj.productVerIndex;
            } catch (error) {
              this.form.upgradeProductVer = "";
            }
          },
        },
      },
      {
        model: "upgradeTitle",
        type: "input",
        label: "标题",
        rules: [
          {
            required: true,
            message: "请输入标题",
            trigger: ["blur", "change"],
          },
        ],
      },
      {
        model: "upgradeProductVer",
        type: "input",
        label: "版本号",
        disabled: this.dialog.click !== "add",
        rules: [
          {
            required: true,
            message: "请输入版本号",
            trigger: ["blur", "change"],
          },
          {
            validator: (r, v, call) => {
              if (checkVersion({ version: v })) {
                call();
              } else {
                return call(new Error("版本号格式不对，请重新输入"));
              }
            },
            trigger: ["blur", "change"],
          },
        ],
      },
      {
        model: "upgradeContent",
        type: "editor",
        label: "内容",
        rules: [
          {
            required: true,
            message: "请输入内容",
            trigger: ["blur", "change"],
          },
        ],
      },
    ];

    await this.format([
      ...formOpt,
      ...(this.dialog.click !== "add" && this.form.isSunyunApp
        ? this.getAddOptions()
        : []),
    ]);
    await this.$nextTick();
    this.show = true;
  },
  methods: {
    getAddOptions() {
      const types = {
        1: [
          { type: "exe", upType: UPGRADELOG_TYPE_STATUS.published },
          { type: "zip", upType: UPGRADELOG_TYPE_STATUS.unpublished },
        ],
        2: [
          { type: "apk", upType: UPGRADELOG_TYPE_STATUS.published },
          { type: "wgt", upType: UPGRADELOG_TYPE_STATUS.unpublished },
        ],
        3: [{ type: "ipa", upType: UPGRADELOG_TYPE_STATUS.published }],
      };
      const tip = `上传类型：${this.dataObj?.sys_operating_system
        .map((x) => `${x.dictLabel}(${types[x.dictValue].map((x) => x.type).join("，")})`)
        .join("、")}`;
      // 限制压缩包类型
      return [
        this.$select({
          key: "listTenant",
          label: "所属商户",
          model: "upgradeTenantIds",
          option: {
            model: "upgradeTenantIds",
            option: {
              multiple: true,
            },
          },
        }),
        {
          model: "upgradeType",
          type: "checkbox-one",
          label: "升级类型",
          option: {
            data: UPGRADELOG_TYPE_DATA,
          },
          rules: [
            {
              required: true,
              message: "请选择升级类型",
              trigger: ["blur"],
            },
          ],
        },
        {
          model: "upgradePackageType",
          type: "checkbox-one",
          label: "安装包类型",
          labelWidth: 100,
          option: {
            data: this.dataObj?.sys_operating_system || [],
            value: "dictValue",
            label: "dictLabel",
          },
          rules: [
            {
              required: true,
              message: "请选择安装包类型",
              trigger: ["blur"],
            },
          ],
        },
        {
          model: "packageFile",
          type: "upload-one",
          label: "上传文件",
          option: {
            type: ["exe", "zip", "apk", "wgt", "ipa"],
            autoUpload: false,
            useFileName: true,
            change: (x) => {
              this.form.upgradeProductVer = getFileVersion(x.fileName);
              this.form.basAttachFile = {
                ...this.form.basAttachFile,
                attachFileName: x.fileName, // 附件名称
                attachFileUrl: x.fileUrl, // 附件地址
                filePath: x.fileUrl, // 文件路径
                fileSize: x.fileSize, // 文件大小
              };
            },
            validate: (x) => {
              if (
                this.dialog.click !== "add" &&
                getFileVersion(x.name, this.form.productVerIndex) !==
                  this.form.upgradeProductVer
              ) {
                this.$message.error("修改只能上传当前版本号的文件！");
                return false;
              }
              const { type } =
                types[this.form.upgradePackageType].find(
                  (x) => x.upType === this.form.upgradeType
                ) || {};

              if (type !== x.name?.split?.(".")?.pop?.()) {
                this.$message.error(`文件格式不对，请选择 ${type} 文件！`);
                return false;
              }
              if (!getFileVersion(x.name, this.form.productVerIndex)) {
                this.$message.error(`文件名包含的版本号格式不对！`);
                return false;
              }
              return true;
            },
            disabled: () =>
              (this.form.upgradeType !== 0 && !this.form.upgradeType) ||
              !this.form.upgradePackageType,
            tip,
          },
          rules: [
            {
              required: true,
              message: "请上传对应安装包",
              trigger: ["change"],
            },
          ],
        },
      ];
    },
  },
};
</script>
<style lang="scss" scoped>
.dialog-content {
}
</style>
